const en = {
  "solar": {
    "boolean": {
      "false": "No",
      "true": "Yes"
    },
    "cancel": "Cancel",
    "myFleet": {
      "energyEarnings": "Energy Earnings",
      "installedCapacity": "Installed Capacity",
      "kWInstalled": "kW installed",
      "kWpInstalled": "kWp installed in total",
      "latestSignal": "Last update",
      "title": "All vehicles",
      "typeToSearch": "Search...",
      "vehicles": "Vehicles",
      "viewDetails": "View details"
    },
  "dashboard": {
      "avgPower": "Avg. Power",
      "backToAllVehicles": "Back to all vehicles",
      "dailyAverageEnergyEarnings": "Daily Average",
      "dailyAverageUsage": "Daily Average Usage PV System",
      "demoDisclaimer": {
        "text": "Energy yields can vary by +-10% respective to an average year due to weather conditions. Driving conditions and energy consumption specifics of the vehicle have impact on the yield as well. The power and energy values here are based on historic climate data in Munich. The data has been obtained from the PVGIS SARAH-2 database. The data is based on a static ~1,4 kWp installation adapted to estimate a bus roof. It is assumed that the bus is 18 days a year out of service. A shading estimation is added to the dataset, as the driving pattern is assumed to reduce the hourly yield because of shadows and surroundings.",
        "title": "Values are for demonstration purposes only."
      },
      "details": {
        "dailyEnergyEarnings": {
          "all": "Select all",
          "editOperatingMode": "Edit",
          "filters": {
            "date": "Time range:",
            "kWh": "Energy earnings (kWh):",
            "title": "Filters ({{active}} active)",
            "to": "to"
          },
          "title": "Daily energy earnings"
        },
        "filters": {
          "addFilter": "Mark data on {{day}} as invalid",
          "editFleetFilter": "Data on {{day}} is marked as invalid for all vehicles",
          "editVehicleFilter": "Data on {{day}} is marked as invalid for {{vehicleName}}",
          "fleetFiltered": "Signals are marked as invalid for all vehicles",
          "markFleetInvalid": "Mark as invalid for all vehicles",
          "markValid": "Mark as valid",
          "markVehicleInvalid": "Mark as invalid for {{vehicleName}}",
          "notFiltered": "Signals are valid",
          "reason": "Enter reason",
          "updateReason": "Update reason",
          "vehicleFiltered": "Signals are marked as invalid for this vehicle"
        },
        "hourlyOutput": "Hourly power output",
        "hourlyOutputDay": "Select day:",
        "kWInstalled": "kW installed",
        "kWpInstalled": "kWp installed",
        "last30Days": "Daily energy earnings",
        "latestSignal": "Latest update",
        "information": "Operation mode signifies whether your system was active or not on a specified date. Please select one or multiple dates in the calendar below to mark when your vehicle was out of service. These days are then showed to you as out of service and are also excluded from the calculations.",
        "operatingMode": {
          "tab": "Edit Operational details",
          "reason": "Reason / comments (optional)",
          "title": "Operating mode for {{vehicleName}} on {{day}}",
          "titleMultipleDays": "Operating mode for {{vehicleName}} on selected days:"
        },
        "title": "Details"
      },
      "dashboardAdminCard": {
        "title": "Admin fleet",
        "text": "This is a special fleet that contains no vehicles but only users.",
        "extraText": "Users who are added to this fleet will have access to all other fleets.",
        "button": "Manage admin users and fleets"
      },
      "downloadCsv": {
        "day": "Day",
        "energyEarningsWh": "Energy earnings (Wh)",
        "label": "Download CSV",
        "tooltip": "Download selected values as CSV file"
      },
      "duration": {
        "allTime": "All time",
        "month": "Month",
        "title": "Duration:",
        "year": "Year"
      },
      "energy": "Energy",
      "excluded": "Not included in the statistics",
      "hours": "hours",
      "includedOperatingModes": "Operating Modes:",
      "kWh": "kWh",
      "monthlyAverageEnergyEarnings": "Monthly Average Energy Earnings",
      "noVehicles": "There are no vehicles in the current fleet.",
      "peakEnergyEarnings": "Highest Day",
      "minNonZeroEnergyEarnings": "Lowest Day*",
      "summary": "Summary",
      "title": "Dashboard",
      "totalEnergyEarnings": "Total Energy Earnings",
      "vehicles": "Vehicles",
      "weeklyAverageEnergyEarnings": "Weekly Average",
      "warning": "*Note: The day signifies the lowest amount of energy earnings from the system above zero kWh."
    },
    "date": "{{day}}/{{month}}/{{year}}",
    "errors": {
      "confirm": "Ok",
      "formErrors": {
      "formFieldError": "The fleet with this {{field}} is already in use.",
      "emptyField": "Please fill out all fields.",
      },
      "errorLoadingData": {
        "text": "An unexpected error occured while loading the page. Please try again.",
        "title": "Error loading data"
      },
      "errorSavingData": {
        "text": "Your data could not be saved. Please try again.",
        "title": "Error saving data"
      },
      "loginError": {
        "text": "Could not log in. Please try again.",
        "title": "Error while logging in"
      },
      "showDetails": "Show details",
      "access_denied": {
        "title": "Access denied",
        "text": "You do not have permission to access this page.",
        "button": "Try again"
      }
    },
    "euros": {
      "delimiter": ",",
      "format": "€{{amount}} ",
      "separator": "."
    },
    "faqs": {
      "edit": {
        "answer": "Answer ({{lang}})",
        "deleteButton": "Delete",
        "editGroup": "Edit group",
        "editQuestion": "Edit question",
        "groupTitle": "Title ({{lang}})",
        "question": "Question ({{lang}})",
        "questionDelete": "this question",
      },
      "intro": "Welcome to the FAQ section! Here you will find answers to the most common questions about our dashboard product. Whether you're new to the platform or a seasoned user, this resource is designed to help you navigate and make the most of your dashboard experience. Browse through the categories to quickly find the information you need. If you can't find the answer you're looking for, feel free to reach out to our support team.",
      "title": "FAQ'S",
      "header": "Frequently Asked Questions"
    },
    "fleet": {
      "add": "Add fleet",
      "addButton": "Add",
      "deleteButton": "Delete",
      "deleteWarning": "Are you sure you want to delete {{entity}}?",
      "deleteTextMessage": "This action cannot be undone and will delete all data associated with {{entity}}.",
      "editButton": "Edit",
      "editTabTitle": "Fleet details",
      "fleets": {
        "add": "Add fleet",
        "edit": "Edit fleet",
        "fleetType": "Type",
        "fleetTypeBusKit": "Bus kit",
        "fleetTypeCampingworld": "Campingworld",
        "fleetTypeSvc3": "SVC3",
        "name": "Name",
        "slug": "Slug",
        "title": "Fleets"
      },
      "name": "Name",
      "operatingModes": {
        "color": "Color",
        "edit": "Edit operating mode",
        "isDefault": "Default?",
        "name": "Name",
        "title": "Operating Modes",
        "tab": "Operating Mode",
        "useForStatistics": "Include in statistics?",
        "in_service": "In service",
        "out_of_service": "Out of service"
      },
      "slug": "Id",
      "users": {
        "add": "Add user",
        "edit": "Edit user",
        "email": "Email",
        "role": "Role",
        "role_admin": "Admin",
        "role_viewer": "Viewer",
        "title": "Users",
        "adminUsers": "Admin Users",
        "editTabTitle": "User details",
      },
      "vehicles": {
        "add": "Add vehicle",
        "deviceIds": "Device IDs",
        "deviceIdsExample": "e.g. 5E883901, 7A046BC1",
        "edit": "Edit vehicle",
        "tabs": {
          "basicData": "Basic data",
          "additionalData": "Additional data",  
          "installMetaData": "Install meta data",
        },
        "installedCapacity": "Installed capacity",
        "installedCapacitykW": "Installed capacity (kW)",
        "installedCapacitykWp": "Installed capacity (kWp)",
        "location": "Location",
        "model": "Model",
        "name": "Name",
        "numberPlate": "License plate",
        "operationMode": "Operation mode",
        "route": "Route",
        "timeZone": "Time zone",
        "title": "Vehicles",
        "validFrom": "Valid data from",
        "validFromDay": "from {{from}}",
        "validity": "Active since",
        "validUntil": "Valid data until",
        "validUntilDay": "until {{until}}",
        "vehicleType": "Vehicle Type",
        "installMetaDataType": "Installed type",
        "installMetaDataMCUVersion": "Installed MCU version",
        "installMetaDataState": "Install state",
        "metaDataStates": {
          "installed": "Installed",
          "planned": "planned",
          "disconnected": "disconnected", 
          "errored": "errored", 
          "unknown": "unknown",
        },
      }
    },
    "myfleet": {
      "title": "My Fleet",
    },
    "vehicleDetails": {
      "title": "Vehicle Details",
      "selectVehicle": "Select Vehicle:",
      "summary": "Summary for %{vehicleName}",
      "hourlyEnergyEarnings": "Hourly Energy Earnings",
      "operationalDetails": "Operational Details",
      "latestSignal": "Last Update",
      "edit": "Edit",
      "selectDates": "Select date(s):",
      "mode": "Mode",
      "included": "Included"
    },
    "weather": {
      "thunderstorm_with_light_rain": "thunderstorm with light rain",
      "thunderstorm_with_rain": "thunderstorm with rain",
      "thunderstorm_with_heavy_rain": "thunderstorm with heavy rain",
      "light_thunderstorm": "light thunderstorm",
      "thunderstorm": "thunderstorm",
      "heavy_thunderstorm": "heavy thunderstorm",
      "ragged_thunderstorm": "ragged thunderstorm",
      "thunderstorm_with_light_drizzle": "thunderstorm with light drizzle",
      "thunderstorm_with_drizzle": "thunderstorm with drizzle",
      "thunderstorm_with_heavy_drizzle": "thunderstorm with heavy drizzle",
      "light_intensity_drizzle": "light intensity drizzle",
      "drizzle": "drizzle",
      "heavy_intensity_drizzle": "heavy intensity drizzle",
      "light_intensity_drizzle_rain": "light intensity drizzle rain",
      "drizzle_rain": "drizzle rain",
      "heavy_intensity_drizzle_rain": "heavy intensity drizzle rain",
      "shower_rain_and_drizzle": "shower rain and drizzle",
      "heavy_shower_rain_and_drizzle": "heavy shower rain and drizzle",
      "shower_drizzle": "shower drizzle",
      "light_rain": "light rain",
      "moderate_rain": "moderate rain",
      "heavy_intensity_rain": "heavy intensity rain",
      "very_heavy_rain": "very heavy rain",
      "extreme_rain": "extreme rain",
      "freezing_rain": "freezing rain",
      "light_intensity_shower_rain": "light intensity shower rain",
      "shower_rain": "shower rain",
      "heavy_intensity_shower_rain": "heavy intensity shower rain",
      "ragged_shower_rain": "ragged shower rain",
      "light_snow": "light snow",
      "snow": "snow",
      "heavy_snow": "heavy snow",
      "sleet": "sleet",
      "light_shower_sleet": "light shower sleet",
      "shower_sleet": "shower sleet",
      "light_rain_and_snow": "light rain and snow",
      "rain_and_snow": "rain and snow",
      "light_shower_snow": "light shower snow",
      "shower_snow": "shower snow",
      "heavy_shower_snow": "heavy shower snow",
      "mist": "mist",
      "smoke": "smoke",
      "haze": "haze",
      "sand_dust_whirls": "sand/dust whirls",
      "fog": "fog",
      "sand": "sand",
      "dust": "dust",
      "volcanic_ash": "volcanic ash",
      "squalls": "squalls",
      "tornado": "tornado",
      "clear_sky": "clear sky",
      "few_clouds": "few clouds: 11-25%",
      "scattered_clouds": "scattered clouds: 25-50%",
      "broken_clouds": "broken clouds: 51-84%",
      "overcast_clouds": "overcast clouds: 85-100%"
  },
    "settings": {
      "title": "Settings",
    },
    "admin": {
      "title": "Admin",
    },
    "documents": {
      "docs": {
        "empty": "There is no documentation so far.",
        "title": "Documentation",
        "view": "View document",
      },
      "reports": {
        "empty": "There are no reports so far.",
        "menuTitle": "Reports",
        "title": "Monthly Reports",
        "view": "View report",
      },
      "fileName": "File Name",
      "month": "Month",
      "latestFirst": "Latest first",
      "oldestFirst": "Oldest first",
    },
    "footer": {
      "contactLabel": "Contact",
      "contactLink": "mailto:digital@sonomotors.com",
      "imprintLabel": "Imprint",
      "imprintLink": "https://sonomotors.com/en/imprint/",
      "logoutLabel": "Logout",
      "privacyLabel": "Privacy Policy",
      "privacyLink": "https://sonomotors.com/en/privacy-policy/",
      "termsLabel": "Terms & Conditions",
      "termsLink": "https://sonomotors.com/en/downloads/terms-and-conditions/",
      "whisteblowerLabel": "Whistleblower Hotline",
      "whisteblowerLink": "https://www.whistleblowerservices.com/sonogroup?language=en",
      "rightsReserved": "© Sono Motors 2024. All rights reserved.",
    },
    "login": {
      "emailAddress": "Email address",
      "login": "Sign in",
      "loginCode": "Login Code",
      "loginInstructions": "In order to log in to the Solar Dashboard, simply enter the email address. We will then immediately send you a login link.",
      "loginLinkRequestedText": "We just emailed a magic link to {{email}}. Use the link from the email or enter the code here to sign in:",
      "loginLinkRequestedTitle": "Please check your inbox",
      "loginWithEmail": "Sign in with email to the Solar Dashboard.",
      "pageTitle": "Login",
      "requestAgain": "Send again",
      "requestLoginLink": "Send me the link",
      "wrongCode": "Hmm, the code you entered seems to be wrong. Please try again or request a new code.",
      "yourSonoAccount": "Your Sono account"
    },
    "noFleets": {
      "text": "Please check if you used the right email address or ask your fleet admin to add your email address to a fleet.",
      "title": "Nothing to see here"
    },
    "ok": "Ok",
    "yes": "Yes",
    "no": "No",
    "typeToSearch": "Search",
    "sortBy": "Sort by",
    "viewAll": "View all",
    "menu": {
      "client": "Client",
      "logout": "Log out",
      "close": "Close",
    },
  }
};

export default en;